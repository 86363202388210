.header{
    width: 100vw;
    height: 10vh;
    background-color: orange;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__title{
    
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: xx-large;
    margin: 1%;
}
.header__newPost{
    color: white;
    font-size: xx-large;
    margin: 1%;
}